export default {
  background: "#fff",
  bodyText: "#fff",
  bg: "#303a52",
  divider: "#fff",
  dropDownItemColor: "#303a52",
  secondaryText: "#fff",
  secondaryBackground: "#303a52",
  secondarySelected: "#574b90",
  secondarySelectedText: "#fff",
  ddItem: "#303a52",
  createBackground: "#fff",
  softHighlight: "##fc85ae",
  modalBg: "#574b90",
  modalOutsideBg: "##fc85ae",
  modalFirstBg: "#303a52",
  modalSecondBg: "#303a52",
  offBg: "#574b90",
  onBg: "#9e579d",
  minusColor: "red",
  modalText: "#6a6a6a",
  lightYellow: "#FFD502",
};
